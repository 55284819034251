import { Cookies } from 'react-cookie';
import Moment from "moment";
import { AREmailDaysEnum, HTMLTemplatesName, TransactionTypeEnum } from '../helpers/crmEnums';

const getUserRoles = () => {
    const cookies = new Cookies();
    const user = getLocalStorage('user');
    const objUser = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
    const role = { isAdmin: false, isSales: false, isOffice: false };
    for (const r of objUser?.roles) {
        if (r.userRoles.role === 'Admin')
            role.isAdmin = true;
        else if (r.userRoles.role === 'Sales')
            role.isSales = true;
        else if (r.userRoles.role === 'Office')
            role.isOffice = true;
    }
    return role;
}
const getActiveCellFormat = (cell, row, rowIndex, extraData) => {
    if (row && row.isActive) {
        return 'Yes';
    } else {
        return 'No';
    }
}
const getTaxableCellFormat = (cell, row, rowIndex, extraData) => {
    if (row && row.isTaxable) {
        return 'Yes';
    } else {
        return 'No';
    }
}
const AmountCellFormat = (cell, row, rowIndex, extraData) => {
    if (cell) {
        const x = cell.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{10})/;
        while (pattern.test(x));
        return x.replace(pattern, "$1,$2");
    } else {
        return '0.00';
    }
};

const DateCellFormat = (cell, row, rowIndex, extraData) => {
    var dateDisplay = '';
    if (cell) {
        dateDisplay = Moment(cell).format('YYYY-MM-DD');
    }
    return dateDisplay;
};

const currentDate = () => {
    var today = new Date(),
        month = today.getMonth() + 1, date = today.getDate(),
        todayDate = today.getFullYear() + '-' + ((month < 10) ? ('0' + month) : (month)) + '-' + ((date < 10) ? ('0' + date) : (date));
    return todayDate;
};

const getRoundedNumber = (number, fixed) => {
    return (Math.round(number * 100) / 100).toFixed(fixed);
};
const numberWithCommas = (x) => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};
const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}
const getLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
}
const makeConstOrEnumAsArray = (enumObject) => {
    var all = [];
    for (var key in enumObject) {
        all.push({name: key , value: enumObject[key]});
    }
    return all;
}

const fetchTemplateFileName = (value) => {
    let fileName;
    switch (value) {
        case AREmailDaysEnum.Day7:
            fileName = HTMLTemplatesName.Email7DayPastDueTemplate;
            break;
        case AREmailDaysEnum.Day15:
            fileName = HTMLTemplatesName.Email15DayPastDueTemplate;
            break;
        case AREmailDaysEnum.Day30:
            fileName = HTMLTemplatesName.Email30DayPastDueTemplate;
            break;
        case TransactionTypeEnum.Estimate:
            fileName = HTMLTemplatesName.PDFEstimateFooterTemplate;
            break;
        case TransactionTypeEnum.Invoice:
            fileName = HTMLTemplatesName.PDFInvoiceFooterTemplate;
            break;
        default:
            fileName = '';
            break;
    }
    return fileName;
}
export {
    getUserRoles,
    getActiveCellFormat,
    AmountCellFormat,
    DateCellFormat,
    currentDate,
    getTaxableCellFormat,
    getRoundedNumber,
    numberWithCommas,
    getLoggedInUser,
    setSession,
    setLocalStorage,
    getLocalStorage,
    makeConstOrEnumAsArray,
    fetchTemplateFileName
};
