import React, { Component } from 'react';
import SimpleMDEReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';


class CRMTextEditor extends Component {
    static defaultProps = {
        delay: 1000,
        value: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            
        }
    }
    handleEditorChange = value =>
        this.setState(prevState => ({ ...prevState, value }));



    render() {
        const { options, delay, id, ...rest } = this.props;
        return (
            <div>
                <SimpleMDEReact
                    {...rest}
                    id={id}
                    value={this.state.value}
                    options={{
                        autosave: {
                            enabled: false,
                            uniqueId: id,
                            delay
                        },
                        spellChecker: true,
                        status: false,
                        ...options
                    }}
                    onChange={this.handleEditorChange}
                />
            </div>
        );
    }
}


export default CRMTextEditor;
