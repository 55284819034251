
export const CustomerStageEnum = {
    Prospect: 1,
    Customer: 2,
    Lead: 3,
}
export const TermsEnum = {
    Net30: 1,
    DueOnReceipt: 2,
}

export const TransactionTypeEnum = {
    Estimate: 1,
   // WorkOrder: 2,
    Invoice: 3
}
export const TransactionTypeName = {
    Estimate:'Estimate',
    Invoice: 'Invoice'
}
export const AREmailDaysEnum = {
    Day7: 7,
    Day15: 15,
    Day30: 30
}

export const HTMLTemplatesName = {
    Email7DayPastDueTemplate: "Email7DayPastDueTemplate",
    Email15DayPastDueTemplate: "Email15DayPastDueTemplate",
    Email30DayPastDueTemplate: "Email30DayPastDueTemplate",
    EmailAREmailSummaryTemplate: "EmailAREmailSummaryTemplate",
    PDFInvoiceFooterTemplate: "_partial-PDFInvoiceFooterTemplate",
    PDFEstimateFooterTemplate: "_partial-PDFEstimateFooterTemplate"

}




